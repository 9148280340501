<template>
<card-template
  :item="item"
  :selected="selected"
  :compact="compact"
>
  <template #content>
    <div class="property property--description"
      v-html="item.data.description"
    ></div>
  </template>
  <template #content-compact>
    <div class="property property--date">
      {{ item.date }}
    </div>
  </template>
</card-template>
</template>

<script>
import cardTemplate from "./card-base-template.vue";

export default {
  name: 'card-task',
  components: {
    cardTemplate,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
</style>